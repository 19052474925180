import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './Routes';
import './index-reset.css';

const root = document.getElementById('root');

function fullScreen() {
    root.style.height = ( window.innerHeight || document.documentElement.clientHeight ) + 'px';
}
fullScreen();

window.addEventListener('resize', fullScreen);
ReactDOM.render(<Routes/>, root);