import React, { Component } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo-expanded.png";

import "./Home.css";

export default class Home extends Component {
  clearStorage(disc) {
    localStorage.setItem(`answer${disc ? "Disc" : "Eneagram"}`, "");
    localStorage.setItem(`user${disc ? "Disc" : "Eneagram"}`, "");
    localStorage.setItem(`resultState${disc ? "Disc" : "Eneagram"}`, "");
  }

  render() {
    return (
      <div className="home">
        <img id="logo" src={logo} alt="Instituto do Conhecimento" />
        <div className="titulo-site">
          <h2 className="titulo-pequeno">Descubra sua</h2>
          <h1 className="titulo-grande">Personalidade</h1>
        </div>

        <div className="card-container">
          <div className="teste-container eneagrama-container">
            <svg
              width="150"
              height="153"
              viewBox="0 0 150 153"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4699 113.25L75 3.07633L136.53 113.25H13.4699Z"
                stroke="black"
                strokeWidth="3"
              />
              <line
                x1="52.54"
                y1="146.344"
                x2="23.54"
                y2="23.3442"
                stroke="black"
                strokeWidth="3"
              />
              <line
                x1="25.5881"
                y1="21.6201"
                x2="147.588"
                y2="73.6201"
                stroke="black"
                strokeWidth="3"
              />
              <line
                y1="-1.5"
                x2="132.62"
                y2="-1.5"
                transform="matrix(-0.919923 0.392098 0.392098 0.919923 125 23)"
                stroke="black"
                strokeWidth="3"
              />
              <line
                x1="52.7937"
                y1="144.892"
                x2="1.79373"
                y2="75.8916"
                stroke="black"
                strokeWidth="3"
              />
              <line
                y1="-1.5"
                x2="126.372"
                y2="-1.5"
                transform="matrix(0.22948 -0.973313 -0.973313 -0.22948 96 146)"
                stroke="black"
                strokeWidth="3"
              />
              <line
                y1="-1.5"
                x2="85.8021"
                y2="-1.5"
                transform="matrix(0.594391 -0.804176 -0.804176 -0.594391 96 144)"
                stroke="black"
                strokeWidth="3"
              />
              <circle cx="75" cy="75" r="73.5" stroke="black" strokeWidth="3" />
            </svg>
            <h2>ENEAGRAMA</h2>
            <p>9 personalidades</p>
            <p>45 perguntas</p>
            <Link
              to="/eneagrama/instrucoes"
              onClick={() => this.clearStorage(false)}
            >
              <div className="button">iniciar teste</div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
