import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import './PoliticaPrivacidade.css';

export default class PoliticaPrivacidade extends Component {
    render() {
        return (
            <div className="politica-privacidade">
                <h1>Política de Privacidade</h1>
                <p>
                    Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a
                    tornar a sua visita no nosso site o mais produtiva e agradável possível.
                </p>
                <p>
                    A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso
                    site é importante para o <Link to="/">Descubra sua Personalidade</Link>.
                </p>
                <p>
                    Todas as informações pessoais relativas a membros, assinantes, clientes ou
                    visitantes que usem o Descubra sua Personalidade serão tratadas em concordância
                    com a <a href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709.htm" target="_blank" rel="noopener noreferrer">
                        Lei da Proteção de Dados Pessoais de 14 de agosto de 2018 (Lei nº 13.709).
                    </a>
                </p>
                <p>
                    A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone
                    e/ou celular, endereço, data de nascimento e/ou outros.
                </p>
                <p>
                    O uso do Descubra sua Personalidade pressupõe a aceitação
                    deste <a href='http://descubrasuapersonalidade.com.br/politica-privacidade' title='acordo de privacidade'>
                        Acordo de Privacidade
                    </a>.
                    Nossa equipe reserva-se ao direito de alterar este acordo
                    sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de
                    privacidade com regularidade de forma a estar sempre atualizado.
                </p>
                
                <h2>Os anúncios</h2>
                <p>
                    Tal como outros websites, coletamos e utilizamos informação contida nos anúncios.
                    A informação contida nos anúncios, inclui o seu endereço IP (Internet Protocol),
                    o seu ISP (Internet Service Provider, como o Sapo, Clix, ou outro), o browser que
                    utilizou ao visitar o nosso website (como o Google Chrome ou o Firefox), o
                    tempo da sua visita e que páginas visitou dentro do nosso website.
                </p>
                
                <h2>Cookie DoubleClick Dart</h2>
                <p>
                    O Google, como fornecedor de terceiros, utiliza cookies para exibir anúncios no
                    nosso website;
                </p>
                <p>
                    Com o cookie DART, o Google pode exibir anúncios com base nas visitas que o leitor
                    fez a outros websites na Internet;
                </p>
                <p>
                    Os utilizadores podem desativar o cookie DART visitando a Política de privacidade
                    da rede de conteúdo e dos anúncios do Google.
                </p>

                <h2>Os Cookies e Web Beacons</h2>
                <p>
                    Utilizamos cookies para armazenar informação, tais como as suas preferências
                    pessoas quando visita o nosso website. Isto poderá incluir um simples popup, ou
                    uma ligação em vários serviços que providenciamos, tais como fóruns.
                </p>
                <p>
                    Em adição também utilizamos publicidade de terceiros no nosso website para
                    suportar os custos de manutenção. Alguns destes publicitários, poderão utilizar
                    tecnologias como os cookies e/ou web beacons quando publicitam no nosso website,
                    o que fará com que esses publicitários (como o Google através do Google AdSense)
                    também recebam a sua informação pessoal, como o endereço IP, o seu ISP, o seu
                    browser, etc. Esta função é geralmente utilizada para geotargeting (mostrar
                    publicidade de São Paulo apenas aos leitores oriundos de São Paulo por ex.) ou
                    apresentar publicidade direcionada a um tipo de utilizador (como mostrar publicidade
                    de restaurante a um utilizador que visita sites de culinária regularmente, por ex.).
                </p>
                <p>
                    Você detém o poder de desligar os seus cookies, nas opções do seu browser, ou
                    efetuando alterações nas ferramentas de programas Anti-Virus, como o Kaspersky.
                    No entanto, isso poderá alterar a forma como interage com o nosso website,
                    ou outros websites. Isso poderá afetar ou não permitir que faça logins em programas,
                    sites ou fóruns da nossa e de outras redes.
                </p>
                
                <h2>Ligações a Sites de terceiros</h2>
                <p>
                    O Descubra sua Personalidade possui ligações para outros sites, os quais, a nosso
                    ver, podem conter informações / ferramentas úteis para os nossos visitantes. A
                    nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso
                    visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo.
                </p>
                <p>
                    Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses
                    mesmos sites.
                </p>
            </div>
        );
    }
}
