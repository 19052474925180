import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Apresentacao from "./pages/Apresentacao/Apresentacao";
import Instrucoes from "./pages/Instrucoes/Instrucoes";
import Perguntas from "./pages/Perguntas/Perguntas";
import Avaliacao from "./pages/Avaliacao/Avaliacao";
import Resultados from "./pages/Resultados/Resultados";
import IconesUtlizados from "./pages/IconesUtilizados/IconesUtilizados";
import PoliticaPrivacidade from "./pages/PoliticaPrivacidade/PoliticaPrivacidade";

export default class Routes extends Component {
  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/eneagrama/instrucoes" component={Instrucoes} />
          <Route path="/disc/instrucoes" component={Instrucoes} />
          <Route path="/eneagrama/perguntas" component={Perguntas} />
          <Route path="/disc/perguntas" component={Perguntas} />
          <Route path="/eneagrama/avaliacao" component={Avaliacao} />
          <Route path="/disc/avaliacao" component={Avaliacao} />
          <Route path="/eneagrama/resultados" component={Resultados} />
          <Route path="/disc/resultados" component={Resultados} />
          <Route path="/icones-utilizados" component={IconesUtlizados} />
          <Route path="/politica-privacidade" component={PoliticaPrivacidade} />
          <Route path="/disc" component={Apresentacao} />
          <Route path="" component={Apresentacao} />
        </Switch>
      </BrowserRouter>
    );
  }
}
