import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './Perguntas.css';
import './escala-resposta.css';
import logoPequeno from '../../assets/images/logo-short.png';
import { perguntasEneagrama } from './perguntas-eneagrama.json';
import { perguntasDisc } from './perguntas-disc.json';

let disc, perguntas;
export default class Perguntas extends Component {
    constructor() {
        super();
        disc = window.location.pathname.slice(1).toLocaleLowerCase().indexOf('disc') === 0;
        perguntas = disc ? perguntasDisc : perguntasEneagrama;
        this.TOTAL_QUESTIONS = perguntas.length;
        this.OPTIONS = 5;
        const answer = JSON.parse(localStorage.getItem(`answer${disc ? 'Disc' : 'Eneagram'}`) || '{"length": 0}');
        const numero = answer.length < perguntas.length ? answer.length + 1 : perguntas.length;
        this.state = {
            numero: numero,
            personalidade: perguntas[numero - 1].personalidade.toLowerCase(),
            pergunta: perguntas[numero - 1].pergunta,
            redirect: answer.length === perguntas.length,
            resposta: -1
        }
    }

    handleOption({ target }) {
        const { personalidade } = this.state;
        if (!personalidade || typeof personalidade !== 'string') {
            console.warn(`Personalidade "${personalidade}"inválida: respostas[${this.state.questionnumero}].personalidade`);
            return;
        }

        const value = parseInt(target.getAttribute('data-id'));

        this.setState({
            numero: this.state.numero,
            personalidade: this.state.personalidade,
            pergunta: this.state.pergunta,
            redirect: this.state.redirect,
            resposta: value
        });
    }

    updateNextButton() {
        const button = document.querySelector('.perguntas .button');
        if (button) //check if button was already rendered
            button.setAttribute('data-status', this.state.resposta !== -1 ? 'enabled' : 'disabled');
    }

    resetAllOptionsStyle() {
        const options = document.querySelectorAll('.perguntas path');
        if (options)
            Object.keys(options).forEach(k => options[k].setAttribute('data-checked', 'false'));
    }

    changeOptionStyle() {
        const options = document.querySelectorAll(`.perguntas path[data-id="${this.state.resposta}"]`);
        if (options)
            Object.keys(options).forEach(k => options[k].setAttribute('data-checked', 'true'));
    }

    updateProgress() {
        const answered = this.state.resposta !== -1 ? this.state.numero : this.state.numero - 1;
        const progress = parseInt(answered / this.TOTAL_QUESTIONS * 100);
        const progressString = parseInt(progress) + '%';

        const bar = document.querySelector('.perguntas .progresso-barra');
        if (bar)
            bar.style.width = progressString;

        const percentage = document.querySelector('.perguntas .progresso-percentual');
        if (percentage)
            percentage.innerText = progressString;
    }

    nextQuestion() {
        const nextQuestion = this.state.numero < this.TOTAL_QUESTIONS ? this.state.numero + 1 : this.state.numero;
        const answer = JSON.parse(localStorage.getItem(`answer${disc ? 'Disc' : 'Eneagram'}`) || '{"length": 0}');
        const personalidade = answer[this.state.personalidade];
        const total = personalidade ? personalidade.total + this.OPTIONS : this.OPTIONS;
        const points = personalidade ? personalidade.points + this.state.resposta : this.state.resposta;
        const percentage = Math.round(points / total * 100);

        answer[this.state.personalidade] = { points, total, percentage };
        answer.length++;
        localStorage.setItem(`answer${disc ? 'Disc' : 'Eneagram'}`, JSON.stringify(answer));

        this.setState({
            numero: nextQuestion,
            personalidade: perguntas[nextQuestion - 1].personalidade.toLowerCase(),
            pergunta: perguntas[nextQuestion - 1].pergunta,
            redirect: this.state.numero === this.TOTAL_QUESTIONS,
            resposta: -1
        });

        //console.log(`Respostas: ${localStorage.getItem('answer')}`);
    }

    componentDidMount() {
        this.updateProgress();
        const paths = document.querySelectorAll('.perguntas path');
        Object.keys(paths).forEach(k => paths[k].addEventListener('click', this.handleOption.bind(this)));
    }

    render() {
        this.resetAllOptionsStyle();
        this.changeOptionStyle();
        this.updateProgress();
        this.updateNextButton();

        if (this.state.redirect)
            return <Redirect to={disc ? '/disc/avaliacao' : '/eneagrama/avaliacao'} push={true} />

        return (
            <div className="perguntas">
                <img src={logoPequeno} alt="logo" />
                <div className="progresso">
                    <div className="progresso-fundo">
                        <div className="progresso-barra"></div>
                    </div>
                    <div className="progresso-percentual">0%</div>
                </div>
                <div className="conteudo">
                    {
                        disc
                            ? (
                                <p className="questao">
                                    <span className="questao-numero">{this.state.numero}. </span>
                                    <span className="intro">Você se considera: </span> <br />
                                    <span className="pergunta-disc">{this.state.pergunta}</span>
                                </p>
                            )
                            : (
                                <p className="questao">
                                    <span className="questao-numero">{this.state.numero}. </span>
                                    <span className="pergunta-eneagrama">{this.state.pergunta}</span>
                                </p>
                            )
                    }
                    <svg className="escala" width="249" height="51" viewBox="0 0 249 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path data-id="5" data-type="field" data-checked="false" d="M212.889 2.94843L245.889 1.11714C247.036 1.05352 248 1.96594 248 3.11406V48C248 49.1046 247.105 50 246 50H213C211.895 50 211 49.1046 211 48V4.94536C211 3.88384 211.829 3.00725 212.889 2.94843Z" />
                        <path data-id="4" data-type="field" data-checked="false" d="M170.898 4.79209L203.898 3.10731C205.041 3.04895 206 3.96009 206 5.10472V47.84C206 48.9446 205.105 49.84 204 49.84H171C169.895 49.84 169 48.9446 169 47.84V6.78949C169 5.72455 169.834 4.84639 170.898 4.79209Z" />
                        <path data-id="3" data-type="field" data-checked="false" d="M127 48V9.13207C127 8.07465 127.823 7.19994 128.879 7.13576L161.879 5.129C163.029 5.05906 164 5.973 164 7.12532V48C164 49.1046 163.105 50 162 50H129C127.895 50 127 49.1046 127 48Z" />
                        <path data-id="2" data-type="field" data-checked="false" d="M119.887 7.11917L86.8874 8.9807C85.8282 9.04045 85 9.91672 85 10.9775V47.84C85 48.9446 85.8954 49.84 87 49.84H120C121.105 49.84 122 48.9446 122 47.84V9.116C122 7.96716 121.034 7.05447 119.887 7.11917Z" />
                        <path data-id="1" data-type="field" data-checked="false" d="M77.8885 9.11787L44.8885 10.96C43.8289 11.0192 43 11.8956 43 12.9569V47.81C43 48.9146 43.8954 49.81 45 49.81H78C79.1046 49.81 80 48.9146 80 47.81V11.1148C80 9.96638 79.0351 9.05386 77.8885 9.11787Z" />
                        <path data-id="0" data-type="field" data-checked="false" d="M35.8877 11.3063L2.88772 13.1617C1.82845 13.2213 1 14.0976 1 15.1586V47.9975C1 49.1021 1.89543 49.9975 3 49.9975H36C37.1046 49.9975 38 49.1021 38 47.9975V13.3031C38 12.1544 37.0346 11.2418 35.8877 11.3063Z" />
                    </svg>

                    <svg className="escala-numero" width="235" height="48" viewBox="-2 0 231 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path data-id="5" data-type="numero" data-checked="false" d="M214.24 21.16C215.733 20.84 217.107 20.68 218.36 20.68C219.853 20.68 221.173 20.9 222.32 21.34C223.467 21.78 224.42 22.3867 225.18 23.16C225.953 23.9333 226.533 24.8467 226.92 25.9C227.32 26.9533 227.52 28.1 227.52 29.34C227.52 30.86 227.253 32.2333 226.72 33.46C226.2 34.6867 225.473 35.74 224.54 36.62C223.62 37.4867 222.533 38.1533 221.28 38.62C220.027 39.0867 218.673 39.32 217.22 39.32C216.38 39.32 215.573 39.2333 214.8 39.06C214.027 38.9 213.3 38.68 212.62 38.4C211.953 38.12 211.333 37.8 210.76 37.44C210.187 37.08 209.68 36.7 209.24 36.3L210.34 34.78C210.58 34.4333 210.893 34.26 211.28 34.26C211.547 34.26 211.847 34.3667 212.18 34.58C212.513 34.78 212.92 35.0067 213.4 35.26C213.88 35.5133 214.44 35.7467 215.08 35.96C215.733 36.16 216.507 36.26 217.4 36.26C218.4 36.26 219.3 36.1 220.1 35.78C220.9 35.46 221.587 35.0067 222.16 34.42C222.733 33.82 223.173 33.1067 223.48 32.28C223.787 31.4533 223.94 30.5267 223.94 29.5C223.94 28.6067 223.807 27.8 223.54 27.08C223.287 26.36 222.893 25.7467 222.36 25.24C221.84 24.7333 221.187 24.34 220.4 24.06C219.613 23.78 218.693 23.64 217.64 23.64C216.92 23.64 216.167 23.7 215.38 23.82C214.607 23.94 213.813 24.1333 213 24.4L210.76 23.74L213.06 10.34H226.64V11.9C226.64 12.4067 226.48 12.8267 226.16 13.16C225.853 13.48 225.32 13.64 224.56 13.64H215.56L214.24 21.16Z" />
                        <path data-id="4" data-type="numero" data-checked="false" d="M179.87 29.177V17.948C179.87 17.6187 179.883 17.2577 179.908 16.865C179.933 16.4723 179.971 16.0733 180.022 15.668L170.104 29.177H179.87ZM186.957 29.177V31.115C186.957 31.3177 186.894 31.4887 186.767 31.628C186.653 31.7673 186.469 31.837 186.216 31.837H182.834V39H179.87V31.837H167.786C167.533 31.837 167.311 31.7673 167.121 31.628C166.944 31.476 166.83 31.2923 166.779 31.077L166.437 29.348L179.68 11.773H182.834V29.177H186.957Z" />
                        <path data-id="3" data-type="numero" data-checked="false" d="M131.855 24.6211H134.299C135.834 24.5977 137.041 24.1934 137.92 23.4082C138.799 22.623 139.238 21.5625 139.238 20.2266C139.238 17.2266 137.744 15.7266 134.756 15.7266C133.35 15.7266 132.225 16.1309 131.381 16.9395C130.549 17.7363 130.133 18.7969 130.133 20.1211H126.881C126.881 18.0938 127.619 16.4121 129.096 15.0762C130.584 13.7285 132.471 13.0547 134.756 13.0547C137.17 13.0547 139.062 13.6934 140.434 14.9707C141.805 16.248 142.49 18.0234 142.49 20.2969C142.49 21.4102 142.127 22.4883 141.4 23.5312C140.686 24.5742 139.707 25.3535 138.465 25.8691C139.871 26.3145 140.955 27.0527 141.717 28.084C142.49 29.1152 142.877 30.375 142.877 31.8633C142.877 34.1602 142.127 35.9824 140.627 37.3301C139.127 38.6777 137.176 39.3516 134.773 39.3516C132.371 39.3516 130.414 38.7012 128.902 37.4004C127.402 36.0996 126.652 34.3828 126.652 32.25H129.922C129.922 33.5977 130.361 34.6758 131.24 35.4844C132.119 36.293 133.297 36.6973 134.773 36.6973C136.344 36.6973 137.545 36.2871 138.377 35.4668C139.209 34.6465 139.625 33.4688 139.625 31.9336C139.625 30.4453 139.168 29.3027 138.254 28.5059C137.34 27.709 136.021 27.2988 134.299 27.2754H131.855V24.6211Z" />
                        <path data-id="2" data-type="numero" data-checked="false" d="M99.779 36.127C100.108 36.127 100.368 36.2233 100.561 36.416C100.754 36.6087 100.85 36.858 100.85 37.164V39H84.598V37.963C84.598 37.7477 84.6433 37.5267 84.734 37.3C84.8247 37.0733 84.9663 36.8637 85.159 36.671L92.962 28.834C93.608 28.1767 94.1973 27.5477 94.73 26.947C95.2627 26.335 95.716 25.723 96.09 25.111C96.464 24.499 96.753 23.8813 96.957 23.258C97.161 22.6233 97.263 21.949 97.263 21.235C97.263 20.521 97.1497 19.8977 96.923 19.365C96.6963 18.821 96.3847 18.3733 95.988 18.022C95.6027 17.6707 95.1437 17.41 94.611 17.24C94.0783 17.0587 93.506 16.968 92.894 16.968C92.2707 16.968 91.6983 17.0587 91.177 17.24C90.6557 17.4213 90.191 17.6763 89.783 18.005C89.3863 18.3223 89.052 18.702 88.78 19.144C88.508 19.586 88.3153 20.0733 88.202 20.606C88.066 21.0027 87.879 21.269 87.641 21.405C87.4143 21.5297 87.0913 21.5637 86.672 21.507L85.091 21.235C85.2497 20.1243 85.5557 19.144 86.009 18.294C86.4737 17.4327 87.0517 16.713 87.743 16.135C88.4457 15.557 89.2447 15.1207 90.14 14.826C91.0353 14.52 92.0043 14.367 93.047 14.367C94.0783 14.367 95.0417 14.52 95.937 14.826C96.8323 15.132 97.6087 15.5797 98.266 16.169C98.9233 16.747 99.439 17.4553 99.813 18.294C100.187 19.1327 100.374 20.0847 100.374 21.15C100.374 22.0567 100.238 22.901 99.966 23.683C99.694 24.4537 99.3257 25.1903 98.861 25.893C98.3963 26.5957 97.858 27.2813 97.246 27.95C96.6453 28.6187 96.005 29.293 95.325 29.973L88.899 36.535C89.3523 36.4103 89.8113 36.314 90.276 36.246C90.7407 36.1667 91.1883 36.127 91.619 36.127H99.779Z" />
                        <path data-id="1" data-type="numero" data-checked="false" d="M58.288 36.824V39H46V36.824H50.912V21.192C50.912 20.7227 50.928 20.248 50.96 19.768L46.88 23.272C46.7413 23.3893 46.6027 23.464 46.464 23.496C46.3253 23.5173 46.1973 23.5173 46.08 23.496C45.9627 23.4747 45.8507 23.432 45.744 23.368C45.648 23.304 45.5733 23.2347 45.52 23.16L44.624 21.928L51.456 16.024H53.776V36.824H58.288Z" />
                        <path data-id="0" data-type="numero" data-checked="false" d="M16.5 28.26C16.5 30.14 16.295 31.77 15.885 33.15C15.485 34.52 14.935 35.655 14.235 36.555C13.535 37.455 12.705 38.125 11.745 38.565C10.795 39.005 9.775 39.225 8.685 39.225C7.585 39.225 6.56 39.005 5.61 38.565C4.67 38.125 3.85 37.455 3.15 36.555C2.45 35.655 1.9 34.52 1.5 33.15C1.1 31.77 0.9 30.14 0.9 28.26C0.9 26.38 1.1 24.75 1.5 23.37C1.9 21.99 2.45 20.85 3.15 19.95C3.85 19.04 4.67 18.365 5.61 17.925C6.56 17.485 7.585 17.265 8.685 17.265C9.775 17.265 10.795 17.485 11.745 17.925C12.705 18.365 13.535 19.04 14.235 19.95C14.935 20.85 15.485 21.99 15.885 23.37C16.295 24.75 16.5 26.38 16.5 28.26ZM13.725 28.26C13.725 26.62 13.585 25.245 13.305 24.135C13.035 23.015 12.665 22.115 12.195 21.435C11.735 20.755 11.2 20.27 10.59 19.98C9.98 19.68 9.345 19.53 8.685 19.53C8.025 19.53 7.39 19.68 6.78 19.98C6.17 20.27 5.635 20.755 5.175 21.435C4.715 22.115 4.345 23.015 4.065 24.135C3.795 25.245 3.66 26.62 3.66 28.26C3.66 29.9 3.795 31.275 4.065 32.385C4.345 33.495 4.715 34.39 5.175 35.07C5.635 35.75 6.17 36.24 6.78 36.54C7.39 36.83 8.025 36.975 8.685 36.975C9.345 36.975 9.98 36.83 10.59 36.54C11.2 36.24 11.735 35.75 12.195 35.07C12.665 34.39 13.035 33.495 13.305 32.385C13.585 31.275 13.725 29.9 13.725 28.26Z" />
                    </svg>

                    <p className="a-ver">
                        <span className="nada-a-ver">Nada a Ver</span>
                        <span className="tudo-a-ver">Tudo a Ver</span>
                    </p>
                </div>
                <div className="button-wrapper">
                    <div className="button" onClick={this.nextQuestion.bind(this)}>Próxima</div>
                </div>
            </div>
        )
    }
}