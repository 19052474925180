import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Instrucoes.css";
import logoPequeno from "../../assets/images/logo-short.png";

let disc;
export default class Instrucoes extends Component {
  constructor() {
    super();
    disc =
      window.location.pathname.slice(1).toLocaleLowerCase().indexOf("disc") ===
      0;

    console.log("Is disc", disc);
  }
  render() {
    return (
      <div className="instrucoes">
        <img src={logoPequeno} alt="logo" />
        <div className="conteudo">
          <h1>Instruções</h1>
          <p>
            <span className="numero">1. </span>
            Seus dados serão utilizados apenas para classificar a sua
            personalidade e estão protegidos conforme a nossa{" "}
            <Link
              to="/politica-privacidade"
              className="politica"
              target="_blank"
            >
              Política de Privacidade
            </Link>
            .
          </p>
          <p>
            <span className="numero">2. </span>
            Caso tenha mais de 25 anos, os resultados serão ainda mais acertivos
            se considerar o que melhor o descrevia quando tinha entre 18 e 25
            anos, conforme era no auge da sua personalidade.
          </p>
          <p>
            <span className="numero">3. </span>
            Quanto mais a pergunta te descrever, mais perto de
            <span className="tudo-a-ver"> Tudo a Ver</span> marque sua resposta.
            Quanto menos o descrever, mais perto de
            <span className="nada-a-ver"> Nada a Ver</span> marque sua resposta.
          </p>
        </div>

        <div class="author">
          <p data-show={!disc}>
            Teste extraído do livro <strong>Eneagrama Para Líderes</strong> de{" "}
            <strong>Nicolai Cursino</strong>.
          </p>
        </div>
        <Link to={disc ? "/disc/perguntas" : "/eneagrama/perguntas"}>
          <div className="button">PERGUNTAS</div>
        </Link>
      </div>
    );
  }
}
