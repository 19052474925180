import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import jQuery from "jquery";
import "jquery-mask-plugin";

import api from "../../util/api";
import "./Avaliacao.css";
import { estados } from "./Cities-Brazil.json";

let disc;
export default class Avaliacao extends Component {
  constructor() {
    super();
    disc =
      window.location.pathname.slice(1).toLocaleLowerCase().indexOf("disc") >=
      0;
  }

  state = {
    buttonClicked: false,
  };

  componentDidMount() {
    const maskBehavior = function (val) {
      return val.replace(/\D/g, "").length === 11
        ? "(00) 00000-0000"
        : "(00) 0000-00009";
    };

    const options = {
      onKeyPress: function (val, e, field, options) {
        field.mask(maskBehavior.apply({}, arguments), options);
      },
    };

    jQuery("#name").change(this.validateName.bind(this));
    jQuery("#email").change(this.validateEmail.bind(this));
    jQuery("#city").change(this.validateCity.bind(this));
    jQuery("#phone").change(this.validatePhone.bind(this));
    jQuery("#phone").mask(maskBehavior, options);

    const formFields = document.querySelectorAll(".avaliacao input");
    Object.keys(formFields).forEach((k) => {
      formFields[k].addEventListener("keydown", this.checkEnter.bind(this));
    });

    this.fillStateListOptions();
  }

  fillStateListOptions() {
    const stateSelect = document.querySelector(
      ".avaliacao .fields #state-select"
    );
    if (!stateSelect) return;
    stateSelect.appendChild(this.createDefaultStateUF());

    Object.keys(estados).forEach((s) => {
      const option = document.createElement("option");
      option.setAttribute("value", estados[s].sigla);
      option.innerText = estados[s].sigla;
      stateSelect.appendChild(option);
    });
  }

  createDefaultStateUF() {
    const defaultOption = document.createElement("option");
    defaultOption.setAttribute("disabled", "");
    defaultOption.setAttribute("selected", "");
    defaultOption.innerText = "UF";
    return defaultOption;
  }

  validateName() {
    const name = jQuery("#name")["0"];
    const parsedName = name.value.replace(/\d/, "");
    const valid =
      name.value.length === parsedName.length && name.value.length >= 3;
    name.parentElement.parentElement.setAttribute(
      "data-status",
      valid ? "valid" : name.value.length === 0 ? "empty" : "error"
    );
    this.checkButtonReady();
  }

  validateCity() {
    const city = jQuery("#city")["0"];
    const cityName = city.value.toLocaleLowerCase();
    const stateSelect = jQuery("#state-select")["0"];

    const states = Object.keys(estados).map((k) => estados[k]);
    const cityList = states.find((s) => s.sigla === stateSelect.value).cidades;
    const cityFound = cityList.filter((c) => cityName === c.toLocaleLowerCase());

    city.parentElement.parentElement.setAttribute(
      "data-status",
      cityFound.length || cityName.length > 3 ? "valid" : cityName ? "error" : "empty"
    );

    if (cityFound.length) {
      city.value = cityFound;
    }
    this.checkButtonReady();
  }

  validateEmail() {
    const email = jQuery("#email")["0"];
    const emailChecker = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const validation = emailChecker.exec(email.value);
    email.parentElement.parentElement.setAttribute(
      "data-status",
      validation ? "valid" : email.value.length === 0 ? "empty" : "error"
    );
    this.checkButtonReady();
  }

  validatePhone() {
    const phone = jQuery("#phone")["0"];
    const length = phone.value.replace(/\D/g, "").length;
    phone.parentElement.parentElement.setAttribute(
      "data-status",
      length === 10 || length === 11
        ? "valid"
        : phone.value.length === 0
        ? "empty"
        : "error"
    );
    this.checkButtonReady();
  }

  checkButtonReady() {
    const nameStatus = jQuery("#name")[
      "0"
    ].parentElement.parentElement.getAttribute("data-status");
    const emailStatus = jQuery("#email")[
      "0"
    ].parentElement.parentElement.getAttribute("data-status");
    const cityStatus = jQuery("#city")[
      "0"
    ].parentElement.parentElement.getAttribute("data-status");
    const phoneStatus = jQuery("#phone")[
      "0"
    ].parentElement.parentElement.getAttribute("data-status");

    const valid =
      nameStatus === "valid" &&
      cityStatus === "valid" &&
      emailStatus === "valid" &&
      phoneStatus === "valid";
    if (valid) this.updateLocalStorage();

    jQuery(".button")["0"].setAttribute("data-enabled", valid + "");
  }

  checkEnter(event) {
    if (event.key === "Enter") {
      const index = event.target.getAttribute("tabindex");
      const next = jQuery(`[tabindex="${parseInt(index) + 1}"]`)["0"];
      next.focus();
    }
  }

  updateLocalStorage() {
    const user = {
      name: document.querySelector("#name").value,
      email: document.querySelector("#email").value,
      state: document.querySelector("#state-select").value,
      city: document.querySelector("#city").value,
      phone: document.querySelector("#phone").value,
    };
    localStorage.setItem(
      `user${disc ? "Disc" : "Eneagram"}`,
      JSON.stringify(user)
    );
  }

  handleButtonClick() {
    if (
      document.querySelector(".button").getAttribute("data-enabled") === "true"
    ) {
      //this.sendToGPages();
      this.sendToDataBase();
      this.setState({ buttonClicked: true });

      //facebook pixel
      // eslint-disable-next-line no-undef
      fbq("track", "Lead");
    }
  }

  sendToGPages() {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200)
        console.log(this.responseText);
    };

    const user = JSON.parse(
      localStorage.getItem(`user${disc ? "Disc" : "Eneagram"}`)
    );

    const msg =
      "nome=" +
      user.name +
      "&" +
      "email=" +
      user.email +
      "&" +
      "text-1576290690134=" +
      user.city +
      " - " +
      user.state +
      "&" +
      "telefone=" +
      user.phone +
      "&" +
      "botao=&" +
      "id_form=1918&" +
      "id_pagina=&" +
      "campanha=";

    const formmatedMsg = encodeURI(msg);

    xhr.open(
      "POST",
      "https://institutodoconhecimento.gpages.com.br/inscricao-/gsend.php"
    );
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.send(formmatedMsg);
  }

  sendToDataBase() {
    const answer = JSON.parse(
      localStorage.getItem(`answer${disc ? "Disc" : "Eneagram"}`)
    );

    const user = JSON.parse(
      localStorage.getItem(`user${disc ? "Disc" : "Eneagram"}`)
    );

    const answerEng = disc
      ? {
          conscientiousness: answer.cauteloso.percentage,
          dominance: answer.dominante.percentage,
          steadiness: answer["estável"].percentage,
          influence: answer.influente.percentage,
        }
      : {
          perfectionist: answer.perfeccionista.percentage,
          helper: answer.ajudante.percentage,
          achiever: answer.vencedor.percentage,
          romantic: answer.intenso.percentage,
          investigator: answer["analítico"].percentage,
          loyalist: answer.precavido.percentage,
          enthusiast: answer.otimista.percentage,
          challanger: answer.poderoso.percentage,
          mediator: answer.mediador.percentage,
        };

    const results = { ...user, ...answerEng };

    api
      .post(`/results/${disc ? "disc" : "enneagram"}`, results)
      .catch((err) => {
        console.warn(err.reponse && err.response.data);
      });
  }

  updateCityOptions(stateTag) {
    const cityOptions = document.querySelector(
      ".avaliacao .fields #cities-brazil"
    );
    while (cityOptions.firstChild) {
      cityOptions.removeChild(cityOptions.firstChild);
    }

    const inputList = document.querySelector(".avaliacao .fields #city");
    inputList.value = "";

    const stateIndex = Object.keys(estados).find(
      (s) => estados[s].sigla === stateTag
    );
    if (stateIndex) {
      estados[stateIndex].cidades.forEach((c) => {
        const option = document.createElement("option");
        option.setAttribute("value", c);
        cityOptions.appendChild(option);
      });
    }
  }

  changeStateBrazil({ target }) {
    this.updateCityOptions(target.value);
    const cityField = document.querySelector(".avaliacao .fields #city");
    cityField.placeholder = "cidade";
    cityField.disabled = false;
    cityField.focus();
  }

  render() {
    const button = document.querySelector(".button");
    const answer = JSON.parse(
      localStorage.getItem(`answer${disc ? "Disc" : "Eneagram"}`) ||
        '{"length":0}'
    );
    return answer.length < (disc ? 4 : 9) ? (
      <Redirect to={disc ? "/disc/perguntas" : "/eneagrama/perguntas"} />
    ) : this.state.buttonClicked ? (
      <Redirect to={disc ? "/disc/resultados" : "/eneagrama/resultados"} />
    ) : (
      <div className="avaliacao">
        <div>
          <h1>Último Passo</h1>
          <p>Por favor, preencha os campos abaixo</p>
        </div>
        <div className="form">
          <div className="field-container" data-status="empty">
            <div className="fields">
              <input
                type="text"
                id="name"
                onBlur={this.validateName.bind(this)}
                placeholder="nome"
                tabIndex="1"
                autoFocus
              />
              <svg
                id="name-icon"
                className="symbols"
                height="512px"
                version="1.1"
                viewBox="0 0 512 512"
                width="512px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path d="M202.4,201.7L202.4,201.7L202.4,201.7z" />
                  <path d="M363.3,363.9c-12.9-4.6-31.4-6.2-43.2-8.8c-6.8-1.5-16.7-5.3-20-9.2c-3.3-4-1.3-40.9-1.3-40.9s6.1-9.6,9.4-18   s6.9-31.4,6.9-31.4s6.8,0,9.2-11.9c2.6-13,6.6-18.4,6.1-28.1c-0.5-9-5.2-9.5-5.7-9.5l0,0c0,0,4.9-13.6,5.6-42.4   C331.1,129.6,305,96,256,96s-75,33.5-74.3,67.6c0.6,28.7,5.6,42.4,5.6,42.4l0,0c-0.5,0-5.2,0.5-5.7,9.5c-0.5,9.7,3.6,14.9,6.1,27.9   c2.4,11.9,9.2,12,9.2,12s3.6,23.1,6.9,31.5c3.3,8.5,9.4,18,9.4,18s2,36.9-1.3,40.9c-3.3,4-13.2,7.7-20,9.2   c-11.9,2.6-30.3,4.3-43.2,8.9C135.8,368.5,96,384,96,416h160h160C416,384,376.2,368.5,363.3,363.9z M256,400H118.7   c2-3,4.7-5.1,8.2-7.6c7-5.1,16.1-9.8,27.1-13.6c6.8-2.4,16.7-4,25.4-5.3c5.7-0.9,11.1-1.7,15.9-2.8c3.4-0.8,20.8-5,28.8-14.6   c4.5-5.4,5.8-12.7,5.6-32.3c-0.1-10-0.6-19.3-0.6-19.7l-0.2-4.2l-2.3-3.5c-1.5-2.3-5.8-9.5-8-15.3c-1.8-4.7-4.6-19.2-6-28.1   c0,0,0.4,1-0.5-3.7c-0.9-4.7-8.4-4.3-9.4-8c-0.9-3.6-1.8-6.9-4.3-18.2c-2.5-11.3,2.8-11.2,3.9-16.2c0.6-3.1,0-5.7,0-5.8l0,0   c-0.3-1-4.1-13.4-4.7-37.7c-0.3-13.2,4.6-25.6,13.8-34.9c10.6-10.8,26-16.5,44.5-16.5c19,0,34,5.7,44.6,16.5   c9.2,9.3,14.1,21.7,13.8,34.9c-0.5,24.2-4.3,36.6-4.7,37.7l0,0c0,0.1-0.6,1.7-0.4,5.2c0.2,5.4,6.8,5.5,4.3,16.8   c-2.5,11.3-3.4,14.6-4.3,18.2c-0.9,3.6-8.5,3.3-9.4,8c-0.9,4.7-0.5,3.7-0.5,3.7c-1.4,8.9-4.2,23.4-6,28.1c-2.3,5.8-6.6,13-8,15.3   l-2.3,3.5l-0.2,4.2c0,0.4-0.5,9.7-0.6,19.7c-0.2,19.6,1.1,26.9,5.6,32.3c8,9.5,25.4,13.8,28.8,14.6c4.8,1.1,10.2,1.9,15.9,2.8   c8.7,1.3,18.6,2.9,25.4,5.3c11,3.9,20.2,8.6,27.1,13.7c3.5,2.5,6.2,4.6,8.2,7.6L256,400L256,400z" />
                </g>
              </svg>
            </div>
            <p className="mensagem-erro">
              O nome deve ter apenas letras e possuir no mínimo 3 caracteres.
            </p>
          </div>

          <div className="field-container" data-status="empty">
            <div className="fields">
              <input type="email" id="email" placeholder="email" tabIndex="2" />
              <span id="at-symbol">@</span>
            </div>
            <p className="mensagem-erro">
              Email incorreto. Formato esperado: exemplo@exemplo.com
            </p>
          </div>

          <div className="field-container" data-status="empty">
            <div className="fields">
              <select
                id="state-select"
                onChange={this.changeStateBrazil.bind(this)}
                defaultValue="SP"
                tabIndex="3"
              ></select>
              <input
                list="cities-brazil"
                name="cities"
                type="text"
                id="city"
                placeholder="<< selecione o estado"
                tabIndex="4"
                onBlur={this.validateCity.bind(this)}
                disabled
              />
              <datalist id="cities-brazil"></datalist>

              <svg
                id="home-icon"
                background="new 0 0 48 48"
                height="48px"
                version="1.1"
                viewBox="0 0 48 48"
                width="48px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Expanded">
                  <g>
                    <g>
                      <path d="M42,48H28V35h-8v13H6V27c0-0.552,0.447-1,1-1s1,0.448,1,1v19h10V33h12v13h10V28c0-0.552,0.447-1,1-1s1,0.448,1,1V48z" />
                    </g>
                    <g>
                      <path d="M47,27c-0.249,0-0.497-0.092-0.691-0.277L24,5.384L1.691,26.723c-0.399,0.381-1.032,0.368-1.414-0.031     c-0.382-0.399-0.367-1.032,0.031-1.414L24,2.616l23.691,22.661c0.398,0.382,0.413,1.015,0.031,1.414     C47.526,26.896,47.264,27,47,27z" />
                    </g>
                    <g>
                      <path d="M39,15c-0.553,0-1-0.448-1-1V8h-6c-0.553,0-1-0.448-1-1s0.447-1,1-1h8v8C40,14.552,39.553,15,39,15z" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <p className="mensagem-erro">Cidade não encrontrada</p>
          </div>

          <div className="field-container" data-status="empty">
            <div className="fields">
              <input
                type="text"
                id="phone"
                placeholder="whatsapp"
                tabIndex="5"
              />
              <svg
                id="whatsapp-icon"
                height="100px"
                version="1.1"
                viewBox="0 0 100 100"
                width="100px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <defs>
                    <rect height="100" id="SVGID_1_" width="100" />
                  </defs>
                  <path d="M95,49.247c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L5,95.406   l7.975-23.522c-4.023-6.606-6.34-14.354-6.34-22.637c0-24.213,19.781-43.841,44.184-43.841C75.223,5.406,95,25.034,95,49.247    M50.818,12.388c-20.484,0-37.146,16.535-37.146,36.859c0,8.066,2.629,15.535,7.076,21.611l-4.641,13.688l14.275-4.537   c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.858C87.964,28.924,71.301,12.388,50.818,12.388    M73.129,59.344c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.494c-0.993-0.359-1.717-0.539-2.438,0.536   c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.334   c-3.219-2.847-5.393-6.364-6.025-7.44c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882   c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.976   c-0.902-2.151-1.803-1.793-2.436-1.793c-0.631,0-1.354-0.09-2.076-0.09s-1.896,0.269-2.889,1.344   c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.114c0.541,0.716,7.49,11.92,18.5,16.223   C63.2,71.177,63.2,69.742,65.186,69.562c1.984-0.179,6.406-2.599,7.312-5.107C73.398,61.943,73.398,59.792,73.129,59.344" />
                </g>
              </svg>
            </div>
            <p className="mensagem-erro">
              Número incorreto. Formato esperado: (xx) xxxxx-xxxx
            </p>
          </div>

          <Link
            className="politica"
            to="/politica-privacidade"
            target="_blank"
            tabIndex="7"
          >
            Política de Privacidade
          </Link>
        </div>
        <div className="button-wrapper">
          <Link
            to={
              button && button.getAttribute("data-enabled") === "true"
                ? disc
                  ? "/disc/resultados"
                  : "/eneagrama/resultados"
                : "#"
            }
            className="button"
            data-enabled="false"
            tabIndex="6"
            onClick={this.handleButtonClick.bind(this)}
          >
            RESULTADOS
          </Link>
        </div>
      </div>
    );
  }
}
